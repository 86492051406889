@import "reset.scss";
@import "setting/_fonts.scss";
@import "setting/_mixins.scss";
@import "setting/_options.scss";
@import "setting/_fonts.scss";
@import "setting/_mixins.scss";
@import "setting/_options.scss";


body {
    font-family: $font;
    font-size: $fontsize;
    line-height: 1.4;

    @include sd {
        text-align: center;
    }
    
}


@import "blocks/_all_general.scss";
@import "blocks/_calendar.scss";
@import "blocks/_catalog-v.scss";
@import "blocks/_first.scss";
@import "blocks/_footer.scss";
@import "blocks/_howplay.scss";
@import "blocks/_links.scss";
@import "blocks/_month.scss";
@import "blocks/_presents.scss";
@import "blocks/_seo.scss";
@import "blocks/_title-v.scss";
@import "blocks/_word_by_mask.scss";
@import "blocks/_wp.scss";
@import "blocks/_yafix.scss";