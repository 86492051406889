.howplay {
    &_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        padding: 20px 0;
        border-radius: 24px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        height: 100%;

        &_wr {
            gap: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            @include md {
                margin-bottom: 20px;
            }
        }
        &_title {
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            // margin: 8px 0;
            // margin-top: 20px;
            padding: 0 16px;
        }
        &_txt {
            padding: 0 16px;
            // margin: 8px 0;
            // margin-bottom: 20px;
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            p {
                color: #000;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
            }
            ul{
                li {
                    list-style: disc;
                }
            } 
            ol {
                li {
                    list-style: decimal;
                }
            }
            ul, ol {
                display: flex;
                flex-direction: column;
                align-items: center;
                li {
                    color: #000;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
        }
        &_img {
            // margin: 8px 0;
            padding: 0 16px;
            text-align: center;
            img {
                width: 100%;
                max-width: 100%;
                border-radius: 4px;
                border: 5px solid #000;
                
                @include md {
                    width: auto;
                    margin-bottom: 20px;
                }
            }
            &--bg {
                img {
                    border-radius: 20px;
                    border: none;
                }
                padding: 0;
                margin: 0;

            }
        }
        hr {
            margin: 12px 20%;
        }

    }
}