
a {
    transition: all 0.4s ease;
}
img {
    max-width: 100%;
    height: auto;
}
.svg-icon {
    fill: #fff;
    max-width: 24px;
    max-height: 24px;
    transition: all 0.4s ease;
}
.disnone_md {
    @include md {
        display: none;
    }
}
section {
    padding: 46px 0;
    @include sd {
        padding: 30px 0;
    }
    &.section {
        padding: 16px 0 46px;
        @include sd {
            padding: 5px 0 30px;
        }
    }
}

.in_b_wr {
    text-align: center;
    &_pc {
        @include md {
            display: none;
        }
    }
    &_tab {
        display: none;
        @include md {
            display: block;
        }
        @include esd {
            display: none;
        }
    }
    &_mb {
        display: none;
        @include esd {
            display: block;
        }
    }
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    // border-radius: 4px;
    // background: rgba(245, 245, 245, 0.7);
    // color: #000;
    // font-family: $font;
    // font-size: 14px;
    // font-style: normal;
    // font-weight: 600;
    // line-height: normal;
    // letter-spacing: 0.15px;
    // padding: 12px 30px;
    // backdrop-filter: blur(20px);
    // border: 1px solid rgba(245, 245, 245, 0.7);

    span {
        line-height: 1;
    }
    .svg-icon {
        fill: #000;
        max-width: 16px;
        max-height: 16px;
    }
    &:hover, &:active, &:focus {
        background: $primaryColor;
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #000;
        
        .svg-icon {
            fill: #fff;
        }
    }
    &_cont {
        width: 100%;
        @include md {
            width: auto;
        }
    }
    &_dark {
        background: $darkColor;
        color: #fff;
        border-color: $darkColor;
        // &:hover, &:active, &:focus {
        //     background: #fff;
        //     background-color: #fff;
        //     color: #000;
        //     border-color: $darkColor;
        // }
    }
    &_dark_invert {
        border-color: $darkColor;
        color: $darkColor;
        // &:hover, &:active, &:focus {
        //     background: $darkColor;
        //     color: #fff;
        //     border-color: $darkColor;
        // }

    }
    &_min {
        padding: 8px 16px;
        font-family: $font;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
    }
    &_long {
        padding: 12px 50px;
    }
    &_blur {
        background: rgba(0, 0, 0, 0.30);
        backdrop-filter: blur(20px);
        color: #fff;
        border-color: transparent;

        // &:hover, &:active, &:focus {
        //     background: #fff;
        //     background-color: #fff;
        //     border-color: transparent;
        //     color: #000;
        //     border-color: #fff;
        // }
    }
    &_standart {

    }
    &_primary {
        background: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
        .svg-icon {
            fill: #fff;
        }
        &:hover, &:active, &:focus {
            background: $darkColor;
            border-color: $darkColor;
            color: #fff;
            .svg-icon {
                fill: #fff;
            }
        }
    }
    &_gray {
        border-color: transparent;
        
        &:hover, &:active, &:focus {
            color: #000;
            .svg-icon {
                fill: #000;
            }
        }
    }
    &_white {
        background: #FFF;
        border-color: #fff;
        // &:hover {
        //     background: rgba(245, 245, 245, 0.7);
        //     background-color: rgba(245, 245, 245, 0.7);
        //     border-color: transparent;
        //     color: #000;
        //     border-color: transparent;
        // }

    }
    &_white_invert {
        border-color: #FFF;
        color: #FFF;
        background-color: transparent;
        // &:hover, &:active, &:focus {
        //     background: #FFF;
        //     color: $darkColor;
        //     border-color: #FFF;
        // }

    }
}

.section {
    padding: 64px 0;
    @include md {
        padding: 44px 0;
    }
}

.title {
    &_h2 {
        margin-bottom: 24px;
        h2 {
            color: #000;
            font-family: $font2;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            letter-spacing: 0.15px;
            
            @include md {
                font-size: 32px;
            }
            @include sd {
                font-size: 28px;
            }
        }
        &--martop {
            margin-top: 30px;
        }
        p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            opacity: 0.7;
            margin-top: 10px;
        }
        &--archive {
            margin-top: 36px;
        }
    }
    &_desc {
        color: #747474;
        font-family: $font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 155%;
        margin-top: 8px;
    }
}

.posa {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}




.swiper-button-next,
.swiper-button-prev {
    user-select: none;
    right: -30px;

    &:after {
        display: none;
    }
}
.swiper-button-prev {
    
    left: -30px;
    transform: rotate(180deg);
}
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -30px;
}


.swiper {
    .swiper-button-next,
    .swiper-button-prev {
        user-select: none;

        &:after {
            display: none;
        }
    }
    .first_btn_next, 
    .first_btn_prev{
        width: 40px;
        // right: calc(50% - (1320px / 2));
        right: calc((100% - 1320px) / 4);
        opacity: 0.5;
        @include md {
            display: none;
        }
        
        @include xl {
            // right: calc(50% - (1140px / 2));
            right: calc((100% - 1140px) / 4);
        }
        @include ld {
            // right: calc(50% - (960px / 2));
            right: calc((100% - 960px) / 4);
        }
        @include md {
            display: none;
            // right: calc(50% - (720px / 2));
        }
        @include sd {
            // right: calc(50% - (540px / 2));
        }
        @include esd {
            right: 0;
        }
        .svg-icon {
            fill: #fff;
            max-width: 40px;
            max-height: 40px;
        }        
        &:after {
            display: none;
        } 
    }
    .first_btn_prev {
        // left: calc(50% - (1320px / 2));
        left: calc((100% - 1320px) / 4);
        right: auto;
        
        @include xl {
            // left: calc(50% - (1140px / 2));
            left: calc((100% - 1140px) / 4);
            right: auto;
        }
        @include ld {
            // left: calc(50% - (960px / 2));
            left: calc((100% - 960px) / 4);
            right: auto;
        }
        @include md {
            display: none;
            // left: calc(50% - (720px / 2));
        }
        @include sd {
            // left: calc(50% - (540px / 2));
        }
        @include esd {
            left: 0;
        }
        right: auto;
        .svg-icon {
            transform: rotate(180deg);
        }        
    }
    .swiper-pagination {
        display: flex;
        gap: 8px;
        bottom: 32px;
        width: 1320px;
        margin: auto;
        left: calc(50% - (1320px / 2));
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        
        @include xl {
            width: 1140px;
            left: calc(50% - (1140px / 2));
        }
        @include ld {
            width: 960px;
            left: calc(50% - (960px / 2));
        }
        @include md {
            width: 720px;
            left: calc(50% - (720px / 2));
        }
        @include sd {
            width: 540px;
            left: calc(50% - (540px / 2));
        }
        @include esd {
            width: 100%;
            left: 0;
        }
    }
    .swiper-pagination-bullet {
        cursor: pointer;
        width: 100%;
        border-radius: 0;
        background: rgba(255, 255, 255, 0.25);
        opacity: 1;
        height: 4px;
        backdrop-filter: blur(20px);
        transition: all .4s ease;
    

        &-active {
            background: #FFF;
        }
    }

    &_arrows {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 35px;
    }
    &_btn_prev,
    &_btn_next {
        border-radius: 4px;
        border: 2px solid #000;
        backdrop-filter: blur(7px);
        padding: 12px;
        cursor: pointer;

        .svg-icon {
            fill: #000;
            max-width: 18px;
            max-height: 18px;
        }
        
        &.swiper-button-disabled {
            cursor: default;
            opacity: 0.3;
        }
    }
    &_btn_prev {
        transform: rotate(180deg);
    }
}

.swiper_btn_prev, 
.swiper_btn_next, 
.swiper-pagination {
    user-select: none;
}



body.hidden {
    overflow: hidden;
}



.page404 {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    border-radius: 33px;


    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &_over {
        z-index: 9990;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        background-color: #000;

    }
    &_img {
        @include sd {
            text-align: center;
        }
        img, svg {
    
            @include sd {
                max-width: 90%;
                margin: auto;
            }
        }
    }
    &_cont {
        display: flex;
        align-items: center;
        gap: 48px;
    
        &_txt {
            h2 {
    
            }
            p {
                margin-top: 8px;
            }
        }
        &_btn {
            a {
        
            }
            @include sd {
                width: 100%;
            }
        }
        @include sd {
            flex-direction: column;
            gap: 24px;
        }
    }
}


/*---------------------*/

.btn {
    padding: 12px 26px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    @include ld {
        font-size: 15px;
        padding: 12px 20px;
    }
}

// .first_info_item_toogle {
//     .toggle-button-cover {
//         display: table-cell;
//         position: relative;
//         width: 200px;
//         height: 140px;
//         box-sizing: border-box;
//       }
      
//     //   .button-cover {
//     //     height: 100px;
//     //     margin: 20px;
//     //     background-color: #fff;
//     //     box-shadow: 0 10px 20px -8px #c5d6d6;
//     //     border-radius: 4px;
//     //   }
      
//       .button-cover:before {
//         counter-increment: button-counter;
//         content: counter(button-counter);
//         position: absolute;
//         right: 0;
//         bottom: 0;
//         color: #d7e3e3;
//         font-size: 12px;
//         line-height: 1;
//         padding: 5px;
//       }
      
//       .button-cover,
//       .knobs,
//       .layer {
//         position: absolute;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         left: 0;
//       }
      
//       .button {
//         position: relative;
//         top: 50%;
//         width: 74px;
//         height: 36px;
//         margin: -20px auto 0 auto;
//         overflow: hidden;
//       }
      
//       .button.r,
//       .button.r .layer {
//         border-radius: 100px;
//       }
      
//       .button.b2 {
//         border-radius: 2px;
//       }
      
//       .checkbox {
//         position: relative;
//         width: 100%;
//         height: 100%;
//         padding: 0;
//         margin: 0;
//         opacity: 0;
//         cursor: pointer;
//         z-index: 3;
//       }
      
//       .knobs {
//         z-index: 2;
//       }
      
//     //   .layer {
//     //     width: 100%;
//     //     background-color: #ebf7fc;
//     //     transition: 0.3s ease all;
//     //     z-index: 1;
//     //   }
      
//       #button-1 .knobs:before {
//         content: "Да";
//         position: absolute;
//         top: 4px;
//         left: 4px;
//         width: 20px;
//         height: 10px;
//         color: #fff;
//         font-size: 10px;
//         font-weight: bold;
//         text-align: center;
//         line-height: 1;
//         padding: 9px 4px;
//         background-color: $greenColor;
//         border-radius: 50%;
//         transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
//       }
      
//       #button-1 .checkbox:checked + .knobs:before {
//         content: "Нет";
//         left: 42px;
//         background-color: #f44336;
//       }
      
//       #button-1 .checkbox:checked ~ .layer {
//         background-color: #fcebeb;
//       }
      
//       #button-1 .knobs,
//       #button-1 .knobs:before,
//       #button-1 .layer {
//         transition: 0.3s ease all;
//       }
// }

$toggle-indicator-size: 24px; // changing this number will resize the whole toggle
$track-height: $toggle-indicator-size + 6;
$track-width: $toggle-indicator-size * 2.5;
$highContrastModeSupport: solid 2px transparent;
/* 

The following vars come from my theme. 
You'll need to replace with your own color values. 

- "$light"
- "$mid"
- "$dark"

*/
$light: #fff;
$mid: 1px;
$dark: #000;
$speed: 0.5s;

$track-border: $mid;
$track-background: $light;
$focus-ring: 0px 0px 0px 2px $dark;

// Toggle specific styles
.toggle {
	align-items: center;
	border-radius: 100px;
	display: flex;
	font-weight: 700;
	margin-bottom: 16px;
    color: #fff;

	&:last-of-type {
		margin: 0;
	}
}

// Since we can't style the checkbox directly, we "hide" it so we can draw the toggle.
.toggle__input {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;

	// This style sets the focus ring. The ":not([disabled])" prevents the focus ring from creating a flash when the element is clicked.
	&:not([disabled]):active + .toggle-track,
	&:not([disabled]):focus + .toggle-track {
		border: 1px solid transparent;
		box-shadow: $focus-ring;
	}

	&:disabled + .toggle-track {
		cursor: not-allowed;
		opacity: 0.7;
	}
}

.toggle-track {
	background: $track-background;
	border: 1px solid $track-border;
	border-radius: 100px;
	cursor: pointer;
	display: flex;
	height: $track-height;
	margin-right: 12px;
	position: relative;
	width: $track-width;
}

.toggle-indicator {
	align-items: center;
	background: $dark;
	border-radius: $toggle-indicator-size;
	bottom: 2px;
	display: flex;
	height: $toggle-indicator-size;
	justify-content: center;
	left: 2px;
	outline: $highContrastModeSupport;
	position: absolute;
	transition: $speed;
	width: $toggle-indicator-size;
}

// The check mark is optional
.checkMark {
	fill: #fff;
	height: $toggle-indicator-size - 4;
	width: $toggle-indicator-size - 4;
	opacity: 0;
	transition: opacity $speed ease-in-out;
}

.toggle__input:checked + .toggle-track .toggle-indicator {
	background: $dark;
	transform: translateX($track-width - $track-height);

	.checkMark {
		opacity: 1;
		transition: opacity $speed ease-in-out;
	}
}

@media screen and (-ms-high-contrast: active) {
	.toggle-track {
		border-radius: 0;
	}
}

.toggle_text {
    color: #fff;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-weight: normal;

    &--big {
        font-size: 18px;
        opacity: 0.9;
    }
    &--min {
        font-size: 12px;
        opacity: 0.7;
    }
}

/*---------------------*/