.hello-block {
  padding: 40px 0px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: 42px;
    line-height: 120%;
    text-align: center;

    margin-bottom: 32px;
  }

  &__description {
    max-width: 700px;
    width: 100%;
    text-align: center;

    font-size: 16px;
    line-height: 140%;
  }
}

.helper {
  user-select: none;
  padding: 40px 0px;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    padding: 40px 80px;
    background-color: #2c2c2e;
    border-radius: 24px;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__title {
    font-size: 24px;
    line-height: 120%;
    color: #fff;
  }

  &__mask {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__mask-button {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    background-color: #ffffff59;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    cursor: pointer;

    transition: all 0.3s ease;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  &__input-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
  }

  .letter {
    width: 54px;
    height: 58px;
    border-radius: 4px;
    background-color: #fbfcfd;
    color: #696969;
    padding: 12px 0px;

    outline: none;
    border: none;

    font-size: 30px;
    line-height: 30px;
    text-align: center;

    text-transform: uppercase;
  }

  &__flex {
    display: flex;
    gap: 42px;
  }

  &__flex-item {
    width: 270px;

    input {
      width: 100%;
    }
  }

  &__label-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    width: 100%;

    margin-bottom: 12px;
  }

  &__input-label {
    font-size: 24px;
    line-height: 140%;
    color: #ffffff;
    font-weight: 600;
  }

  &__big-input {
    height: 58px;
    width: 100%;

    border-radius: 4px;
    background-color: #fbfcfd;
    color: #696969;
    padding: 12px 24px;

    outline: none;
    border: none;

    font-size: 30px;
    line-height: 30px;
    text-transform: uppercase;
  }

  &__button {
    padding: 12px 26px;
    border-radius: 12px;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;

    cursor: pointer;

    transition: all 0.3s ease;

    margin-top: 24px;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  &__words-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 12px;

    p {
      width: calc(100% / 6);
      min-width: 100px;
      padding: 0px 4px;
      color: #fff;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
}

.info {
  position: relative;

  &:hover {
    .info__text {
      opacity: 1;
      pointer-events: auto;
      top: -10px;
    }
  }

  &__text {
    position: absolute;
    z-index: 3;
    width: 280px;
    padding: 12px;
    top: 0px;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    text-align: center;
    background-color: #454547;
    border-radius: 12px;

    transition: all 0.3s ease;

    color: white;

    pointer-events: none;
  }
}

.about-service {
  padding: 40px 0px;
}

@media (max-width: 660px) {
  .hello-block {
    &__title {
      font-size: 36px;
      line-height: 120%;
      text-align: center;

      margin-bottom: 16px;
    }

    &__description {
      font-size: 16px;
      line-height: 110%;
    }
  }

  .helper {
    &__content {
      gap: 24px;

      padding: 24px;
      border-radius: 16px;
    }

    &__mask-button {
      min-width: 32px;
      max-width: 32px;
      min-height: 32px;
      max-height: 32px;
    }

    .letter {
      width: 52px;
      height: 56px;
    }

    &__flex {
      flex-direction: column;
      width: 100%;
      gap: 20px;
    }

    &__flex-item {
      width: 100%;
    }

    &__input-label {
      font-size: 20px;
    }

    &__big-input {
      height: 48px;
    }
  }

  .info {
    &__text {
      left: 200%;
      transform: translateX(-100%) translateY(-100%);
    }
  }
}
