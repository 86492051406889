
.post-item {
    margin-top: 85px;
}
.first_info_item.first_info_right.first_info_flex {
    max-width: 100%;
    justify-content: flex-start;
}

.first_info_item_img img {
    max-height: 555px;
    object-fit: cover;
}
.first_info_item_img_2 img {
    max-width: 290px;
	max-height: 290px;
	object-fit: contain;
}

.first_info_item_word {
	display: flex;
	gap: 10px;
}

.first_info_item_btns_btn.btn--green {
	background-color: #10b981;
    font-size: 18px;
    font-weight: 600;
}


.countdown-wrapper {
	text-align: center;
	color: #fff;
}
.countdown-title {
	font-size: 18px;
	margin-bottom: 15px;
}
.countdown-timer {
	display: flex;
	justify-content: center;
	gap: 20px;
}
.countdown-item {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.countdown-item span:first-child {
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 5px;
}
.countdown-label {
	font-size: 14px;
	color: #666;
}
.calendar_item_word.calendar_item_word--future {
    width: 100%;
    opacity: 0.5;
}
.calendar_item_word--future .calendar_item_word_w {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    width: 100%;
}
.calendar_item .calendar_item_title .svg-icon {
    fill: #cdcdcd;
}
body .first_info_item_txt--date {
    font-size: 14px;
    opacity: 0.5;
}

@media (max-width: 991.98px) {
    .first_info_item_word {
        text-align: center; 
        justify-content: center;
    }
	body .first_info_item_txt h2 {
		text-align: left;
	}
	.first_info_item.first_info_right.first_info_flex {
		justify-content: center;
		padding: 0;
	}
	body .first_info_right,
    body .first_info_item {
        padding: 0;
    }
	body .first_info_right {
        text-align: center;
        margin-top: 0;
    }
	body .first_info_item_img {
		text-align: center;
	}
	body .first_info_item.first_info_right.first_info_flex {
		width: 100%;
	}
	.first_info_item_img img {
		max-height: 290px;
		object-fit: contain;
	}
	.first_info_item_word {
		flex-wrap: wrap;
	}
	.countdown-wrapper {
		width: 100%;
	}

}




.pagination {
    text-align: center;
    margin: 30px 0;
}

.pagination ul {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination a,
.pagination span {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-decoration: none;
}

.pagination .current {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}