.month {
    .first_info {
        background-color: #fff;
        padding: 30px 50px;
        align-items: center;

        &_item {
            h2 {
                color: #000;
                letter-spacing: .15px;
                font-size: 26px;
            }
            &_word_other {
                margin-bottom: 0;
            }
            &_word_min {
                background: #fff;
                color: #000;
                border: 1px solid rgba(0, 0, 0, .1);
            }
        }
    }
}