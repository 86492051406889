$fontsize: 14px;

// Основные цвета
$primaryColor: var(--primary-color, var(--dark-btn-color-h, #eac704));
$primaryColor2: var(--primary-color2, var(--dark-btn-color2-h, #F44336));
// $primaryColor: var(--dark-btn-color-h, --primary-color, #1363F5);
$darkColor: var(--dark-btn-color, #2C2C2E);
$greenColor: var(--dark-btn-color-h, #10B981);
$gradientGreen: conic-gradient(from 182deg at 110.07% -30.21%, rgba(5, 150, 105, 0.70) 4.1773078590631485deg, rgba(52, 211, 153, 0.70) 25.29925525188446deg, rgba(5, 150, 105, 0.70) 52.208431363105774deg), #10B981;;

@keyframes slideDown {
    from {
        top: -100px;
    }
    to {
        top: 0;
    }
}
@keyframes slideFade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes slideFade2 {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 1;
    }
}
// $primaryColorH: #1000D0;
// $secondaryColor: #ff005c;
// $secondaryColorH: #ff005c;
// $lightColor: #ffffff;
// $darkColor: #0d0628;

// // Кнопки
// $btnColor: #1400ff;
// $btnColorH: #1000D0;
// $btnColorText: #FAFAFB;
// $btnColorTextH: #ffffff;

// // Заголовки
// $headingDark: #0d0628;
// $headingLight: #FAFAFB;

// // Тексты
// $textDark: #0d0628;
// $textLight: #FAFAFB;

// // Градиент
// $gradientDark: #08041A;
// $gradientLight: #575283;
// $bgGradient:   linear-gradient(to right, $gradientDark, $gradientLight);

// // Градиент второй
// $GradientDark2: #2D19FF;
// $GradientLight2: #FF005C;
// $bgGradient2:   linear-gradient(to right, $GradientDark2, $GradientLight2);



// $containerWidth: 1320px;
// @media (max-width: 1199.98px) {
//     $containerWidth: 1120px;
// }
// @media (max-width: 991.98px) {
//     $containerWidth: 120px;
// }
// @media (max-width: 767.98px) {
//     $containerWidth: 20px;
// }

// @media (max-width: 575.98px) {
//     $containerWidth: 10px;
// }
