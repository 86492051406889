

.header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    &_logo {
        font-size: 26px;
        &_n {
            font-weight: 900;
            padding: 5px;
            color: $primaryColor;
            background-color: $darkColor;
            width: 34px;
            height: 34px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            line-height: 34px;
            border-radius: 4px;
            border: 1px solid $darkColor;
            position: relative;
            img {
                max-width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
            }
            &.--a {
                color: $primaryColor2;
            }
            &--vs {
                padding: 0;
                overflow: hidden;
                line-height: 1;
                img {
                    max-width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        &_posf {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: center;
        }
        &_m {
            color: $darkColor;
            background-color: transparent;
        }
        &_space {
            width: 5px;
            display: inline-block;
        }
        &_t {
            text-transform: uppercase;
            color: #000;
            letter-spacing: 3px;
            padding-left: 5px;

        }
    }
    &_cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0;
        
        @include sd {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &_item {
        
    }
    &_nav {
        @include sd {
            margin-top: 20px;
        }
        &_ul {
            display: flex;
            flex-wrap: wrap;
            
            @include sd {
                justify-content: center;
            }
        }
        &_item {
            &_link {
                color: #000;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 0 25px;
                border-right: 1px solid rgba(0, 0, 0, 0.10);

            }
            &:last-child {
                a {
                    border-right: none;
                }
            }
        }
    }
}


.first {
    &_title {
        margin: 0 0 46px;
        @include sd {
            margin: 0 0 30px;
        }
        h1 {
            color: #000;
            text-align: center;
            font-size: 42px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @include ld {
                font-size: 36px;
            }
            @include sd {
                font-size: 26px;
            }
        }
    }

    &_info {
        border-radius: 24px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: $darkColor;
        padding: 50px;
        display: flex;
        position: relative;
        justify-content: space-between;
        @include sd {
            flex-wrap: wrap;
            padding: 30px;
        }
        &_flex  {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
            justify-content: center;
        }
        &_wr {
            &.--marbot {
                margin-bottom: 15px;
            }
        }
        &_left {
            text-align: left;
        }
        &_right {
            text-align: right;
            @include md {
                padding-left: 15px;
            }
            @include sd {
                text-align: center;
                margin-top: 20px;
            }
            .first_info_item_txt {
                max-width: 60%;
                margin-left: auto;
                @include md {
                    max-width: 100%;
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }
        &_item {
            max-width: 50%;
            @include sd {
                max-width: 100%;
            }
            h2 {
                color: #FFF;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                @include ld {
                    font-size: 26px;
                }
                @include sd {
                    font-size: 18px;
                    text-align: center;
                }
            }
            &_word {
                margin: 17px 0;
                // text-align: center;
                position: relative;
                transition: all 0.4s ease;
                // width: 65%;
                @include md {
                    width: 100%;
                }
                &_other {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    margin-bottom: 17px;
                }
                &_btn {
                    border-radius: 12px;
                    background: $gradientGreen;
                    padding: 12px 26px;
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    // height: 45px;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                }
                &_min {
                    border-radius: 12px;
                    background: $gradientGreen;
                    padding: 12px 26px;
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    padding: 12px 20px;
                }
                &_top {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    z-index: 20;
                    font-size: 14px;
                    line-height: 1.4;
                    text-align: center;
                    transition: all 0.4s ease;
                    
                    &.active {
                        opacity: 1;
                        &:hover {
                            opacity: 0;
                        }
                    }
                }
                &_bot {
                    font-weight: 900;
                    letter-spacing: 16px;
                    font-size: 34px;
                    z-index: 1;
                    // opacity: 0;
                    // position: absolute;
                    // top: 0;
                    // bottom: 0;
                    // right: 0;
                    // left: 0;
                    transition: all 0.4s ease;
                    display: inline-block;

                    @include sd {
                        font-size: 24px;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                &_big {
                    font-weight: 900;
                    letter-spacing: 16px;
                    font-size: 34px;
                    padding: 12px 26px;
                    display: inline-block;

                    @include sd {
                        font-size: 24px;
                    }
                }
            }
            &_txt {
                margin: 17px 0;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                opacity: 0.7;
                a {
                    text-decoration: underline;
                    color: #FFF;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            &_btns {
                margin: 24px 0 0;
                display: flex;
                gap: 18px;
                flex-wrap: wrap;
                @include ld {
                    gap: 12px;
                }
                @include sd {
                    justify-content: center;
                }
            }
            &_qr {
                img {
                    padding: 15px;
                    background-color: #fff;
                    border-radius: 8px;
                }
            }
            &_share {
                
                display: flex;
                gap: 10px;
                align-items: center;
                // position: absolute;
                // right: 50px;
                justify-content: flex-end;
                @include sd {
                    justify-content: center;
                }
                .first_info_item_txt {
                    margin: 0;
                }
                &.--left {
                    justify-content: flex-start;
                }   

            }
            &_img {
                img {
                    max-width: 290px;
                    padding: 15px;
                    background-color: #fff;
                    border-radius: 8px;
                }
                &.--min {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    align-items: center;

                    img {
                        max-width: 190px;
                    }
                }
            }
            .first_info_item_txt h2 {
                font-size: 18px;
                font-weight: normal;
                text-transform: none;
                margin-bottom: 5px;
                margin-top: 20px;
                opacity: 0.6;
            }
        }
        &_ul {
            margin-top: 15px;
        }
    }
}
