.footer {
    background-color: $darkColor;
    padding: 28px 0;
    color: #fff;

    &_top {
        &_item {
            text-align: center;
            margin-bottom: 20px;

            .header_logo_m {
                color: #fff;
                border: 1px solid #fff;
            }
        }
        &_copyright {
            opacity: 0.7;
        }
    }
    &_subbottom {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba(255, 255, 255, 0.20);
        padding-top: 20px;
        flex-wrap: wrap;
        gap: 8px;

        @include md {
            justify-content: center;
        }

        &_nav {
            display: flex;
            gap: 16px;
            a {
                color: #fff;
                opacity: 0.7;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }   
        }
        &_disk {
            text-align: right;
            color: #fff;
            opacity: 0.7;
        }
    }
}