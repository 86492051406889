.presents {
    &_wrap {
        border-radius: 24px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: $darkColor;
        padding: 50px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        @include md {
            flex-wrap: wrap;
            gap: 20px;
        }
        @include sd {
            padding: 30px;
        }
    }
    &_item {
        width: calc(50% - 16px);
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
        
        @include md {
            width: 100%;
        }
        &_title {
            color: #FFF;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            
            @include sd {
                font-size: 20px;
            }
        }
        &_txt {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            opacity: 0.7;
            @include sd {
                font-size: 14px;
            }
        }
        &_blocks {
            display: flex;
            justify-content: space-between;
            height: 100%;
            @include esd {
                flex-wrap: wrap;
            }

            &_item {
                border-radius: 14px;
                border: 1px solid rgba(255, 255, 255, 0.1);
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 8px;
                height: 100%;
                justify-content: center;
                transition: all 0.4s ease;
                // background-image: url(../img/icon_pres.png);
                // background-repeat: no-repeat;
                // background-position: left;

                // gap: 2px;
                &_wr {
                    width: calc(100% / 3 - 12px);
                    gap: 16px;
                    display: flex;
                    flex-direction: column;
                    @include sd {
                        width: calc(100% / 3 - 8px);
                    }
                    @include esd {
                        width: calc(100% / 2 - 4px);
                        gap: 8px;
                        margin-bottom: 8px;
                    }
                    &:last-child {
                        @include esd {
                            width: 100%;
                        }
                    }

                }

                &:hover,
                &.active {
                    background-color: $primaryColor;
                    .presents_item_blocks_title {
                        color: #000;
                    }
                    .presents_item_blocks_txt {
                        color: #000;
                    }
                }
            }
            &_title {
                color: #fff;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                margin-top: 4px;
            }
            &_txt {
                color: #fff;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                opacity: 0.7;
            }
            &_icon {
                img {
                    max-width: 20px;
                    height: auto;
                }
            }
        }

        &_slider {
            width: 100%;
            position: relative;
            &_item {
                border-radius: 24px;
                background: rgba(255, 255, 255, 0.65);
                backdrop-filter: blur(22.5px);
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 16px;
                &_img {
                    img {
                        max-width: 80px;
                        height: auto;
                        @include esd {
                            max-width: 50px;
                        }
                    }
                }
                &_cont {
                    &_title {
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        margin-bottom: 12px;
                    }
                    &_txt {
                        color: #000;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
            }
        }

    }
}