/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div pre,
a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

@keyframes slideDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}
@keyframes slideFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideFade2 {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}
@keyframes slideFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideFade2 {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.4;
}
@media (max-width: 767.98px) {
  body {
    text-align: center;
  }
}

a {
  transition: all 0.4s ease;
}

img {
  max-width: 100%;
  height: auto;
}

.svg-icon {
  fill: #fff;
  max-width: 24px;
  max-height: 24px;
  transition: all 0.4s ease;
}

@media (max-width: 991.98px) {
  .disnone_md {
    display: none;
  }
}

section {
  padding: 46px 0;
}
@media (max-width: 767.98px) {
  section {
    padding: 30px 0;
  }
}
section.section {
  padding: 16px 0 46px;
}
@media (max-width: 767.98px) {
  section.section {
    padding: 5px 0 30px;
  }
}

.in_b_wr {
  text-align: center;
}
@media (max-width: 991.98px) {
  .in_b_wr_pc {
    display: none;
  }
}
.in_b_wr_tab {
  display: none;
}
@media (max-width: 991.98px) {
  .in_b_wr_tab {
    display: block;
  }
}
@media (max-width: 575.98px) {
  .in_b_wr_tab {
    display: none;
  }
}
.in_b_wr_mb {
  display: none;
}
@media (max-width: 575.98px) {
  .in_b_wr_mb {
    display: block;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.btn span {
  line-height: 1;
}
.btn .svg-icon {
  fill: #000;
  max-width: 16px;
  max-height: 16px;
}
.btn:hover, .btn:active, .btn:focus {
  background: var(--primary-color, var(--dark-btn-color-h, #eac704));
  background-color: var(--primary-color, var(--dark-btn-color-h, #eac704));
  border-color: var(--primary-color, var(--dark-btn-color-h, #eac704));
  color: #000;
}
.btn:hover .svg-icon, .btn:active .svg-icon, .btn:focus .svg-icon {
  fill: #fff;
}
.btn_cont {
  width: 100%;
}
@media (max-width: 991.98px) {
  .btn_cont {
    width: auto;
  }
}
.btn_dark {
  background: var(--dark-btn-color, #2C2C2E);
  color: #fff;
  border-color: var(--dark-btn-color, #2C2C2E);
}
.btn_dark_invert {
  border-color: var(--dark-btn-color, #2C2C2E);
  color: var(--dark-btn-color, #2C2C2E);
}
.btn_min {
  padding: 8px 16px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}
.btn_long {
  padding: 12px 50px;
}
.btn_blur {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  color: #fff;
  border-color: transparent;
}
.btn_primary {
  background: var(--primary-color, var(--dark-btn-color-h, #eac704));
  border-color: var(--primary-color, var(--dark-btn-color-h, #eac704));
  color: #fff;
}
.btn_primary .svg-icon {
  fill: #fff;
}
.btn_primary:hover, .btn_primary:active, .btn_primary:focus {
  background: var(--dark-btn-color, #2C2C2E);
  border-color: var(--dark-btn-color, #2C2C2E);
  color: #fff;
}
.btn_primary:hover .svg-icon, .btn_primary:active .svg-icon, .btn_primary:focus .svg-icon {
  fill: #fff;
}
.btn_gray {
  border-color: transparent;
}
.btn_gray:hover, .btn_gray:active, .btn_gray:focus {
  color: #000;
}
.btn_gray:hover .svg-icon, .btn_gray:active .svg-icon, .btn_gray:focus .svg-icon {
  fill: #000;
}
.btn_white {
  background: #FFF;
  border-color: #fff;
}
.btn_white_invert {
  border-color: #FFF;
  color: #FFF;
  background-color: transparent;
}

.section {
  padding: 64px 0;
}
@media (max-width: 991.98px) {
  .section {
    padding: 44px 0;
  }
}

.title_h2 {
  margin-bottom: 24px;
}
.title_h2 h2 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.15px;
}
@media (max-width: 991.98px) {
  .title_h2 h2 {
    font-size: 32px;
  }
}
@media (max-width: 767.98px) {
  .title_h2 h2 {
    font-size: 28px;
  }
}
.title_h2--martop {
  margin-top: 30px;
}
.title_h2 p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
  margin-top: 10px;
}
.title_h2--archive {
  margin-top: 36px;
}
.title_desc {
  color: #747474;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  margin-top: 8px;
}

.posa {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.swiper-button-next,
.swiper-button-prev {
  user-select: none;
  right: -30px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}

.swiper-button-prev {
  left: -30px;
  transform: rotate(180deg);
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -30px;
}

.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  user-select: none;
}
.swiper .swiper-button-next:after,
.swiper .swiper-button-prev:after {
  display: none;
}
.swiper .first_btn_next,
.swiper .first_btn_prev {
  width: 40px;
  right: calc((100% - 1320px) / 4);
  opacity: 0.5;
}
@media (max-width: 991.98px) {
  .swiper .first_btn_next,
  .swiper .first_btn_prev {
    display: none;
  }
}
@media (max-width: 1399.98px) {
  .swiper .first_btn_next,
  .swiper .first_btn_prev {
    right: calc((100% - 1140px) / 4);
  }
}
@media (max-width: 1199.98px) {
  .swiper .first_btn_next,
  .swiper .first_btn_prev {
    right: calc((100% - 960px) / 4);
  }
}
@media (max-width: 991.98px) {
  .swiper .first_btn_next,
  .swiper .first_btn_prev {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .swiper .first_btn_next,
  .swiper .first_btn_prev {
    right: 0;
  }
}
.swiper .first_btn_next .svg-icon,
.swiper .first_btn_prev .svg-icon {
  fill: #fff;
  max-width: 40px;
  max-height: 40px;
}
.swiper .first_btn_next:after,
.swiper .first_btn_prev:after {
  display: none;
}
.swiper .first_btn_prev {
  left: calc((100% - 1320px) / 4);
  right: auto;
  right: auto;
}
@media (max-width: 1399.98px) {
  .swiper .first_btn_prev {
    left: calc((100% - 1140px) / 4);
    right: auto;
  }
}
@media (max-width: 1199.98px) {
  .swiper .first_btn_prev {
    left: calc((100% - 960px) / 4);
    right: auto;
  }
}
@media (max-width: 991.98px) {
  .swiper .first_btn_prev {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .swiper .first_btn_prev {
    left: 0;
  }
}
.swiper .first_btn_prev .svg-icon {
  transform: rotate(180deg);
}
.swiper .swiper-pagination {
  display: flex;
  gap: 8px;
  bottom: 32px;
  width: 1320px;
  margin: auto;
  left: calc(50% - 660px);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}
@media (max-width: 1399.98px) {
  .swiper .swiper-pagination {
    width: 1140px;
    left: calc(50% - 570px);
  }
}
@media (max-width: 1199.98px) {
  .swiper .swiper-pagination {
    width: 960px;
    left: calc(50% - 480px);
  }
}
@media (max-width: 991.98px) {
  .swiper .swiper-pagination {
    width: 720px;
    left: calc(50% - 360px);
  }
}
@media (max-width: 767.98px) {
  .swiper .swiper-pagination {
    width: 540px;
    left: calc(50% - 270px);
  }
}
@media (max-width: 575.98px) {
  .swiper .swiper-pagination {
    width: 100%;
    left: 0;
  }
}
.swiper .swiper-pagination-bullet {
  cursor: pointer;
  width: 100%;
  border-radius: 0;
  background: rgba(255, 255, 255, 0.25);
  opacity: 1;
  height: 4px;
  backdrop-filter: blur(20px);
  transition: all 0.4s ease;
}
.swiper .swiper-pagination-bullet-active {
  background: #FFF;
}
.swiper_arrows {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 35px;
}
.swiper_btn_prev, .swiper_btn_next {
  border-radius: 4px;
  border: 2px solid #000;
  backdrop-filter: blur(7px);
  padding: 12px;
  cursor: pointer;
}
.swiper_btn_prev .svg-icon, .swiper_btn_next .svg-icon {
  fill: #000;
  max-width: 18px;
  max-height: 18px;
}
.swiper_btn_prev.swiper-button-disabled, .swiper_btn_next.swiper-button-disabled {
  cursor: default;
  opacity: 0.3;
}
.swiper_btn_prev {
  transform: rotate(180deg);
}

.swiper_btn_prev,
.swiper_btn_next,
.swiper-pagination {
  user-select: none;
}

body.hidden {
  overflow: hidden;
}

.page404 {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page404_over {
  z-index: 9990;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  background-color: #000;
}
@media (max-width: 767.98px) {
  .page404_img {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .page404_img img, .page404_img svg {
    max-width: 90%;
    margin: auto;
  }
}
.page404_cont {
  display: flex;
  align-items: center;
  gap: 48px;
}
.page404_cont_txt p {
  margin-top: 8px;
}
@media (max-width: 767.98px) {
  .page404_cont_btn {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .page404_cont {
    flex-direction: column;
    gap: 24px;
  }
}

/*---------------------*/
.btn {
  padding: 12px 26px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
@media (max-width: 1199.98px) {
  .btn {
    font-size: 15px;
    padding: 12px 20px;
  }
}

/* 

The following vars come from my theme. 
You'll need to replace with your own color values. 

- "$light"
- "$mid"
- "$dark"

*/
.toggle {
  align-items: center;
  border-radius: 100px;
  display: flex;
  font-weight: 700;
  margin-bottom: 16px;
  color: #fff;
}
.toggle:last-of-type {
  margin: 0;
}

.toggle__input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.toggle__input:not([disabled]):active + .toggle-track, .toggle__input:not([disabled]):focus + .toggle-track {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #000;
}
.toggle__input:disabled + .toggle-track {
  cursor: not-allowed;
  opacity: 0.7;
}

.toggle-track {
  background: #fff;
  border: 1px solid 1px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  height: 30px;
  margin-right: 12px;
  position: relative;
  width: 60px;
}

.toggle-indicator {
  align-items: center;
  background: #000;
  border-radius: 24px;
  bottom: 2px;
  display: flex;
  height: 24px;
  justify-content: center;
  left: 2px;
  outline: solid 2px transparent;
  position: absolute;
  transition: 0.5s;
  width: 24px;
}

.checkMark {
  fill: #fff;
  height: 20px;
  width: 20px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.toggle__input:checked + .toggle-track .toggle-indicator {
  background: #000;
  transform: translateX(30px);
}
.toggle__input:checked + .toggle-track .toggle-indicator .checkMark {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

@media screen and (-ms-high-contrast: active) {
  .toggle-track {
    border-radius: 0;
  }
}
.toggle_text {
  color: #fff;
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-weight: normal;
}
.toggle_text--big {
  font-size: 18px;
  opacity: 0.9;
}
.toggle_text--min {
  font-size: 12px;
  opacity: 0.7;
}

/*---------------------*/
.calendar_rows {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1199.98px) {
  .calendar_rows--titles {
    display: none;
  }
}
.calendar_item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 14.2857142857%;
  border: 1px solid #F9F9F9;
  order: 0;
  transition: all 0.4s ease;
}
@media (max-width: 1199.98px) {
  .calendar_item {
    width: 16.6666666667%;
  }
}
@media (max-width: 991.98px) {
  .calendar_item {
    width: 25%;
  }
}
@media (max-width: 767.98px) {
  .calendar_item {
    width: 33.3333333333%;
  }
}
@media (max-width: 575.98px) {
  .calendar_item {
    width: 50%;
  }
}
.calendar_item--title {
  background: #F9F9F9;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.calendar_item--date {
  min-height: 140px;
}
.calendar_item--date:hover {
  border-color: var(--primary-color, var(--dark-btn-color-h, #eac704));
  border-radius: 5px;
}
.calendar_item--month .calendar_item_title_date {
  max-width: 80%;
  text-align: right;
}
@media (max-width: 1199.98px) {
  .calendar_item--empty {
    display: none;
  }
}
.calendar_item_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.calendar_item_title_date {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 16px;
}
.calendar_item_title .svg-icon {
  fill: #059669;
  max-width: 14px;
  max-height: 14px;
}
.calendar_item_word_txt {
  color: #424242;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.1px;
  text-align: right;
  margin-bottom: 5px;
  opacity: 0.7;
}
.calendar_item_word_txt a {
  color: #424242;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.1px;
  text-align: right;
  margin-bottom: 5px;
  opacity: 0.7;
}
.calendar_item_word_w {
  border-radius: 5px;
  background: #CDCDCD;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 10px;
  text-align: center;
  padding: 15px 5px;
}
@media (max-width: 575.98px) {
  .calendar_item_word_w {
    font-size: 16px;
    letter-spacing: 8px;
  }
}
.calendar_item_word_w.active {
  background: var(--primary-color, var(--dark-btn-color-h, #eac704));
}
.calendar_item.active {
  background: #F1F0FD;
}
.calendar_item.active .calendar_item_word_w {
  background: var(--primary-color, var(--dark-btn-color-h, #eac704));
}
.calendar_item.active .calendar_item_title .svg-icon {
  fill: #059669;
}
.calendar_item.active .calendar_item_title_date {
  background-color: #059669;
  color: #fff;
}
.calendar_item.future .calendar_item_title .svg-icon {
  fill: #4F4F4F;
}
.calendar_item.future .calendar_item_word {
  opacity: 0;
}

.catalog {
  min-height: 60vh;
}
.catalog .first_info {
  background-color: #fff;
  padding: 30px 50px;
  align-items: center;
}
.catalog .first_info_item {
  width: 80%;
  max-width: 80%;
}
@media (max-width: 767.98px) {
  .catalog .first_info_item {
    width: 100%;
    max-width: 100%;
  }
}
.catalog .first_info_item.first_info_right {
  width: 20%;
}
@media (max-width: 767.98px) {
  .catalog .first_info_item.first_info_right {
    max-width: 100%;
    width: 100%;
    order: 1;
  }
}
@media (max-width: 767.98px) {
  .catalog .first_info_item.first_info_left {
    order: 2;
  }
}
.catalog .first_info_item h2 {
  color: #000;
  letter-spacing: 0.15px;
  font-size: 26px;
}
.catalog .first_info_item_word_other {
  margin-bottom: 0;
  margin-top: 25px;
  gap: 15px 40px;
}
.catalog .first_info_item_word_min {
  background: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.catalog .first_info_item .dictionary-item {
  color: #487bce;
  border-bottom: 1px dashed;
  padding-bottom: 0px;
}
.catalog .first_info_item .dictionary-item:hover {
  border-color: transparent;
  color: #000;
}
.catalog .first_info_item .dictionary-item--tag {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #487bce;
}
.catalog .first_info_item .dictionary-item--letter {
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .catalog .first_info_item_img img {
    max-width: 90px;
  }
}
.catalog .first_info_all {
  width: 100%;
  max-width: 100%;
}
.catalog .first_info_item_img.--min {
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .catalog .first_info_item_img.--min {
    justify-content: flex-start;
  }
}
@media (max-width: 767.98px) {
  .catalog .title_h2 {
    text-align: left;
  }
}
@media (max-width: 767.98px) {
  .catalog .title_h2 h2 {
    text-align: left;
  }
}
.catalog--word .first_info_item h3,
.catalog--word .first_info_item h2 {
  text-transform: none;
  margin-bottom: 30px;
  font-weight: normal;
}
@media (max-width: 767.98px) {
  .catalog--word .first_info_item h3,
  .catalog--word .first_info_item h2 {
    text-align: left;
  }
}
@media (max-width: 767.98px) {
  .catalog--word .first_info_item h3 h2, .catalog--word .first_info_item h3 h3,
  .catalog--word .first_info_item h2 h2,
  .catalog--word .first_info_item h2 h3 {
    text-align: left;
  }
}

.dictionary {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
  margin-top: 25px;
}
.dictionary--word .dictionary-item-wr {
  text-align: left;
  width: 30%;
}
@media (max-width: 767.98px) {
  .dictionary--word .dictionary-item-wr {
    width: calc(50% - 5px);
  }
}

.pagination {
  display: flex;
  gap: 10px;
}
.pagination--martop {
  margin-top: 24px;
}

.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.header_logo {
  font-size: 26px;
}
.header_logo_n {
  font-weight: 900;
  padding: 5px;
  color: var(--primary-color, var(--dark-btn-color-h, #eac704));
  background-color: var(--dark-btn-color, #2C2C2E);
  width: 34px;
  height: 34px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 34px;
  border-radius: 4px;
  border: 1px solid var(--dark-btn-color, #2C2C2E);
  position: relative;
}
.header_logo_n img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.header_logo_n.--a {
  color: var(--primary-color2, var(--dark-btn-color2-h, #F44336));
}
.header_logo_n--vs {
  padding: 0;
  overflow: hidden;
  line-height: 1;
}
.header_logo_n--vs img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_logo_posf {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}
.header_logo_m {
  color: var(--dark-btn-color, #2C2C2E);
  background-color: transparent;
}
.header_logo_space {
  width: 5px;
  display: inline-block;
}
.header_logo_t {
  text-transform: uppercase;
  color: #000;
  letter-spacing: 3px;
  padding-left: 5px;
}
.header_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
}
@media (max-width: 767.98px) {
  .header_cont {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .header_nav {
    margin-top: 20px;
  }
}
.header_nav_ul {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .header_nav_ul {
    justify-content: center;
  }
}
.header_nav_item_link {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 25px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.header_nav_item:last-child a {
  border-right: none;
}

.first_title {
  margin: 0 0 46px;
}
@media (max-width: 767.98px) {
  .first_title {
    margin: 0 0 30px;
  }
}
.first_title h1 {
  color: #000;
  text-align: center;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 1199.98px) {
  .first_title h1 {
    font-size: 36px;
  }
}
@media (max-width: 767.98px) {
  .first_title h1 {
    font-size: 26px;
  }
}
.first_info {
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--dark-btn-color, #2C2C2E);
  padding: 50px;
  display: flex;
  position: relative;
  justify-content: space-between;
}
@media (max-width: 767.98px) {
  .first_info {
    flex-wrap: wrap;
    padding: 30px;
  }
}
.first_info_flex {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}
.first_info_wr.--marbot {
  margin-bottom: 15px;
}
.first_info_left {
  text-align: left;
}
.first_info_right {
  text-align: right;
}
@media (max-width: 991.98px) {
  .first_info_right {
    padding-left: 15px;
  }
}
@media (max-width: 767.98px) {
  .first_info_right {
    text-align: center;
    margin-top: 20px;
  }
}
.first_info_right .first_info_item_txt {
  max-width: 60%;
  margin-left: auto;
}
@media (max-width: 991.98px) {
  .first_info_right .first_info_item_txt {
    max-width: 100%;
  }
}
.first_info_right .first_info_item_txt:first-child {
  margin-top: 0;
}
.first_info_item {
  max-width: 50%;
}
@media (max-width: 767.98px) {
  .first_info_item {
    max-width: 100%;
  }
}
.first_info_item h2 {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 1199.98px) {
  .first_info_item h2 {
    font-size: 26px;
  }
}
@media (max-width: 767.98px) {
  .first_info_item h2 {
    font-size: 18px;
    text-align: center;
  }
}
.first_info_item_word {
  margin: 17px 0;
  position: relative;
  transition: all 0.4s ease;
}
@media (max-width: 991.98px) {
  .first_info_item_word {
    width: 100%;
  }
}
.first_info_item_word_other {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 17px;
}
.first_info_item_word_btn {
  border-radius: 12px;
  background: conic-gradient(from 182deg at 110.07% -30.21%, rgba(5, 150, 105, 0.7) 4.1773078591deg, rgba(52, 211, 153, 0.7) 25.2992552519deg, rgba(5, 150, 105, 0.7) 52.2084313631deg), #10B981;
  padding: 12px 26px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.first_info_item_word_min {
  border-radius: 12px;
  background: conic-gradient(from 182deg at 110.07% -30.21%, rgba(5, 150, 105, 0.7) 4.1773078591deg, rgba(52, 211, 153, 0.7) 25.2992552519deg, rgba(5, 150, 105, 0.7) 52.2084313631deg), #10B981;
  padding: 12px 26px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 12px 20px;
}
.first_info_item_word_top {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 20;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  transition: all 0.4s ease;
}
.first_info_item_word_top.active {
  opacity: 1;
}
.first_info_item_word_top.active:hover {
  opacity: 0;
}
.first_info_item_word_bot {
  font-weight: 900;
  letter-spacing: 16px;
  font-size: 34px;
  z-index: 1;
  transition: all 0.4s ease;
  display: inline-block;
}
@media (max-width: 767.98px) {
  .first_info_item_word_bot {
    font-size: 24px;
  }
}
.first_info_item_word_bot:hover {
  opacity: 1;
}
.first_info_item_word_big {
  font-weight: 900;
  letter-spacing: 16px;
  font-size: 34px;
  padding: 12px 26px;
  display: inline-block;
}
@media (max-width: 767.98px) {
  .first_info_item_word_big {
    font-size: 24px;
  }
}
.first_info_item_txt {
  margin: 17px 0;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
}
.first_info_item_txt a {
  text-decoration: underline;
  color: #FFF;
}
.first_info_item_txt a:hover {
  text-decoration: none;
}
.first_info_item_btns {
  margin: 24px 0 0;
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
}
@media (max-width: 1199.98px) {
  .first_info_item_btns {
    gap: 12px;
  }
}
@media (max-width: 767.98px) {
  .first_info_item_btns {
    justify-content: center;
  }
}
.first_info_item_qr img {
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
}
.first_info_item_share {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .first_info_item_share {
    justify-content: center;
  }
}
.first_info_item_share .first_info_item_txt {
  margin: 0;
}
.first_info_item_share.--left {
  justify-content: flex-start;
}
.first_info_item_img img {
  max-width: 290px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
}
.first_info_item_img.--min {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.first_info_item_img.--min img {
  max-width: 190px;
}
.first_info_item .first_info_item_txt h2 {
  font-size: 18px;
  font-weight: normal;
  text-transform: none;
  margin-bottom: 5px;
  margin-top: 20px;
  opacity: 0.6;
}
.first_info_ul {
  margin-top: 15px;
}

.footer {
  background-color: var(--dark-btn-color, #2C2C2E);
  padding: 28px 0;
  color: #fff;
}
.footer_top_item {
  text-align: center;
  margin-bottom: 20px;
}
.footer_top_item .header_logo_m {
  color: #fff;
  border: 1px solid #fff;
}
.footer_top_copyright {
  opacity: 0.7;
}
.footer_subbottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 20px;
  flex-wrap: wrap;
  gap: 8px;
}
@media (max-width: 991.98px) {
  .footer_subbottom {
    justify-content: center;
  }
}
.footer_subbottom_nav {
  display: flex;
  gap: 16px;
}
.footer_subbottom_nav a {
  color: #fff;
  opacity: 0.7;
  text-decoration: underline;
}
.footer_subbottom_nav a:hover {
  text-decoration: none;
}
.footer_subbottom_disk {
  text-align: right;
  color: #fff;
  opacity: 0.7;
}

.howplay_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
}
.howplay_item_wr {
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 991.98px) {
  .howplay_item_wr {
    margin-bottom: 20px;
  }
}
.howplay_item_title {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 0 16px;
}
.howplay_item_txt {
  padding: 0 16px;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.howplay_item_txt p {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.howplay_item_txt ul li {
  list-style: disc;
}
.howplay_item_txt ol li {
  list-style: decimal;
}
.howplay_item_txt ul, .howplay_item_txt ol {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.howplay_item_txt ul li, .howplay_item_txt ol li {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.howplay_item_img {
  padding: 0 16px;
  text-align: center;
}
.howplay_item_img img {
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  border: 5px solid #000;
}
@media (max-width: 991.98px) {
  .howplay_item_img img {
    width: auto;
    margin-bottom: 20px;
  }
}
.howplay_item_img--bg {
  padding: 0;
  margin: 0;
}
.howplay_item_img--bg img {
  border-radius: 20px;
  border: none;
}
.howplay_item hr {
  margin: 12px 20%;
}

.links {
  padding: 20px 0;
}
.links_wr {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.month .first_info {
  background-color: #fff;
  padding: 30px 50px;
  align-items: center;
}
.month .first_info_item h2 {
  color: #000;
  letter-spacing: 0.15px;
  font-size: 26px;
}
.month .first_info_item_word_other {
  margin-bottom: 0;
}
.month .first_info_item_word_min {
  background: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.presents_wrap {
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--dark-btn-color, #2C2C2E);
  padding: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
@media (max-width: 991.98px) {
  .presents_wrap {
    flex-wrap: wrap;
    gap: 20px;
  }
}
@media (max-width: 767.98px) {
  .presents_wrap {
    padding: 30px;
  }
}
.presents_item {
  width: calc(50% - 16px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
@media (max-width: 991.98px) {
  .presents_item {
    width: 100%;
  }
}
.presents_item_title {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .presents_item_title {
    font-size: 20px;
  }
}
.presents_item_txt {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
}
@media (max-width: 767.98px) {
  .presents_item_txt {
    font-size: 14px;
  }
}
.presents_item_blocks {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
@media (max-width: 575.98px) {
  .presents_item_blocks {
    flex-wrap: wrap;
  }
}
.presents_item_blocks_item {
  border-radius: 14px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  height: 100%;
  justify-content: center;
  transition: all 0.4s ease;
}
.presents_item_blocks_item_wr {
  width: calc(33.3333333333% - 12px);
  gap: 16px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767.98px) {
  .presents_item_blocks_item_wr {
    width: calc(33.3333333333% - 8px);
  }
}
@media (max-width: 575.98px) {
  .presents_item_blocks_item_wr {
    width: calc(50% - 4px);
    gap: 8px;
    margin-bottom: 8px;
  }
}
@media (max-width: 575.98px) {
  .presents_item_blocks_item_wr:last-child {
    width: 100%;
  }
}
.presents_item_blocks_item:hover, .presents_item_blocks_item.active {
  background-color: var(--primary-color, var(--dark-btn-color-h, #eac704));
}
.presents_item_blocks_item:hover .presents_item_blocks_title, .presents_item_blocks_item.active .presents_item_blocks_title {
  color: #000;
}
.presents_item_blocks_item:hover .presents_item_blocks_txt, .presents_item_blocks_item.active .presents_item_blocks_txt {
  color: #000;
}
.presents_item_blocks_title {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 4px;
}
.presents_item_blocks_txt {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
}
.presents_item_blocks_icon img {
  max-width: 20px;
  height: auto;
}
.presents_item_slider {
  width: 100%;
  position: relative;
}
.presents_item_slider_item {
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(22.5px);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
}
.presents_item_slider_item_img img {
  max-width: 80px;
  height: auto;
}
@media (max-width: 575.98px) {
  .presents_item_slider_item_img img {
    max-width: 50px;
  }
}
.presents_item_slider_item_cont_title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 12px;
}
.presents_item_slider_item_cont_txt {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.content--bg {
  border-bottom: 5px solid rgb(255, 255, 255);
  background: rgb(247, 247, 248);
}
.content_wr {
  transition: all 0.4s ease;
}
.content_wr h1 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.15px;
  margin-bottom: 32px;
}
@media (max-width: 767.98px) {
  .content_wr h1 {
    font-size: 28px;
  }
}
.content_wr p {
  color: #747474;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 155%;
  margin-bottom: 16px;
}
@media (max-width: 767.98px) {
  .content_wr p {
    font-size: 14px;
  }
}
.content_wr h2, .content_wr h3, .content_wr h4, .content_wr h5, .content_wr h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 130%;
  margin: 24px 0 16px;
}
.content_wr h2 {
  font-size: 35px;
}
@media (max-width: 767.98px) {
  .content_wr h2 {
    font-size: 26px;
  }
}
.content_wr h3 {
  font-size: 30px;
}
@media (max-width: 767.98px) {
  .content_wr h3 {
    font-size: 24px;
  }
}
.content_wr h4 {
  font-size: 25px;
}
@media (max-width: 767.98px) {
  .content_wr h4 {
    font-size: 22px;
  }
}
.content_wr h5 {
  font-size: 20px;
}
@media (max-width: 767.98px) {
  .content_wr h5 {
    font-size: 18px;
  }
}
.content_wr h6 {
  font-size: 15px;
}
.content_wr ul, .content_wr ol {
  margin: 10px 0 16px 20px;
  color: #747474;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 155%;
}
@media (max-width: 767.98px) {
  .content_wr ul, .content_wr ol {
    font-size: 14px;
  }
}
.content_wr ul li {
  list-style: disc;
}
.content_wr ol li {
  list-style: decimal;
}
.content_wr li {
  margin-bottom: 5px;
  line-height: 145%;
}
.content_wr table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Roboto", sans-serif;
  color: #747474;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 155%;
}
@media (max-width: 767.98px) {
  .content_wr table {
    font-size: 14px;
  }
}
.content_wr th, .content_wr td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.content_wr th {
  background-color: #f2f2f2;
  color: #333;
}
.content_wr a {
  color: #007bff;
  text-decoration: none;
}
.content_wr a:hover {
  text-decoration: underline;
}
.content_wr button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.content_wr button:hover {
  background-color: #0056b3;
}
.content_wr blockquote {
  border-left: 4px solid #ddd;
  padding-left: 20px;
  margin: 10px 0;
  font-style: italic;
  color: #666;
}
.content_wr_btn {
  overflow: hidden;
  color: var(--primary-color, var(--dark-btn-color-h, #eac704));
  text-overflow: ellipsis;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 155%;
  margin-top: 24px;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .content_wr_btn {
    font-size: 14px;
  }
}
.content_inside {
  margin-top: 110px;
  position: relative;
}
.content_inside::before {
  content: "";
  position: absolute;
  height: 130px;
  top: -110px;
  right: 0;
  left: 0;
  margin: auto;
  background: rgb(0, 0, 0);
}
.content--h1 {
  border-bottom: 5px solid rgb(255, 255, 255);
  background: rgb(247, 247, 248);
}
.content--h1 .content_wr h1 {
  font-size: 52px;
  margin-bottom: 24px;
}
@media (max-width: 991.98px) {
  .content--h1 .content_wr h1 {
    font-size: 40px;
  }
}
@media (max-width: 767.98px) {
  .content--h1 .content_wr h1 {
    font-size: 36px;
  }
}
.content--h1 .content_wr p {
  font-weight: 400;
}

.content_wr_limited {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  max-height: 150px;
}

.title {
  padding-bottom: 0;
}
@media (max-width: 767.98px) {
  .title__h1 h1 {
    text-align: left;
  }
}
.hello-block {
  padding: 40px 0px;
}
.hello-block .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hello-block__title {
  font-size: 42px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 32px;
}
.hello-block__description {
  max-width: 700px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 140%;
}

.helper {
  user-select: none;
  padding: 40px 0px;
}
.helper__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px 80px;
  background-color: #2c2c2e;
  border-radius: 24px;
}
.helper__title-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}
.helper__title {
  font-size: 24px;
  line-height: 120%;
  color: #fff;
}
.helper__mask {
  display: flex;
  align-items: center;
  gap: 12px;
}
.helper__mask-button {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3490196078);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.helper__mask-button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.helper__mask-button img {
  width: 20px;
  height: 20px;
}
.helper__input-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
}
.helper .letter {
  width: 54px;
  height: 58px;
  border-radius: 4px;
  background-color: #fbfcfd;
  color: #696969;
  padding: 12px 0px;
  outline: none;
  border: none;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
}
.helper__flex {
  display: flex;
  gap: 42px;
}
.helper__flex-item {
  width: 270px;
}
.helper__flex-item input {
  width: 100%;
}
.helper__label-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
}
.helper__input-label {
  font-size: 24px;
  line-height: 140%;
  color: #ffffff;
  font-weight: 600;
}
.helper__big-input {
  height: 58px;
  width: 100%;
  border-radius: 4px;
  background-color: #fbfcfd;
  color: #696969;
  padding: 12px 24px;
  outline: none;
  border: none;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
}
.helper__button {
  padding: 12px 26px;
  border-radius: 12px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 24px;
}
.helper__button:hover {
  background-color: #fff;
  color: #000;
}
.helper__words-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 12px;
}
.helper__words-list p {
  width: 16.6666666667%;
  min-width: 100px;
  padding: 0px 4px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

.info {
  position: relative;
}
.info:hover .info__text {
  opacity: 1;
  pointer-events: auto;
  top: -10px;
}
.info__text {
  position: absolute;
  z-index: 3;
  width: 280px;
  padding: 12px;
  top: 0px;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  text-align: center;
  background-color: #454547;
  border-radius: 12px;
  transition: all 0.3s ease;
  color: white;
  pointer-events: none;
}

.about-service {
  padding: 40px 0px;
}

@media (max-width: 660px) {
  .hello-block__title {
    font-size: 36px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 16px;
  }
  .hello-block__description {
    font-size: 16px;
    line-height: 110%;
  }
  .helper__content {
    gap: 24px;
    padding: 24px;
    border-radius: 16px;
  }
  .helper__mask-button {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
  }
  .helper .letter {
    width: 52px;
    height: 56px;
  }
  .helper__flex {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
  .helper__flex-item {
    width: 100%;
  }
  .helper__input-label {
    font-size: 20px;
  }
  .helper__big-input {
    height: 48px;
  }
  .info__text {
    left: 200%;
    transform: translateX(-100%) translateY(-100%);
  }
}
.post-item {
  margin-top: 85px;
}

.first_info_item.first_info_right.first_info_flex {
  max-width: 100%;
  justify-content: flex-start;
}

.first_info_item_img img {
  max-height: 555px;
  object-fit: cover;
}

.first_info_item_img_2 img {
  max-width: 290px;
  max-height: 290px;
  object-fit: contain;
}

.first_info_item_word {
  display: flex;
  gap: 10px;
}

.first_info_item_btns_btn.btn--green {
  background-color: #10b981;
  font-size: 18px;
  font-weight: 600;
}

.countdown-wrapper {
  text-align: center;
  color: #fff;
}

.countdown-title {
  font-size: 18px;
  margin-bottom: 15px;
}

.countdown-timer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-item span:first-child {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 5px;
}

.countdown-label {
  font-size: 14px;
  color: #666;
}

.calendar_item_word.calendar_item_word--future {
  width: 100%;
  opacity: 0.5;
}

.calendar_item_word--future .calendar_item_word_w {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  width: 100%;
}

.calendar_item .calendar_item_title .svg-icon {
  fill: #cdcdcd;
}

body .first_info_item_txt--date {
  font-size: 14px;
  opacity: 0.5;
}

@media (max-width: 991.98px) {
  .first_info_item_word {
    text-align: center;
    justify-content: center;
  }
  body .first_info_item_txt h2 {
    text-align: left;
  }
  .first_info_item.first_info_right.first_info_flex {
    justify-content: center;
    padding: 0;
  }
  body .first_info_right,
  body .first_info_item {
    padding: 0;
  }
  body .first_info_right {
    text-align: center;
    margin-top: 0;
  }
  body .first_info_item_img {
    text-align: center;
  }
  body .first_info_item.first_info_right.first_info_flex {
    width: 100%;
  }
  .first_info_item_img img {
    max-height: 290px;
    object-fit: contain;
  }
  .first_info_item_word {
    flex-wrap: wrap;
  }
  .countdown-wrapper {
    width: 100%;
  }
}
.pagination {
  text-align: center;
  margin: 30px 0;
}

.pagination ul {
  display: inline-flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a,
.pagination span {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-decoration: none;
}

.pagination .current {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.post-item {
  margin-top: 85px;
}

.first_info_item.first_info_right.first_info_flex {
  max-width: 100%;
  justify-content: flex-start;
}

.first_info_item_img img {
  max-height: 555px;
  object-fit: cover;
}

.first_info_item_img_2 img {
  max-width: 290px;
  max-height: 290px;
  object-fit: contain;
}

.first_info_item_word {
  display: flex;
  gap: 10px;
}

.first_info_item_btns_btn.btn--green {
  background-color: #10b981;
  font-size: 18px;
  font-weight: 600;
}

.countdown-wrapper {
  text-align: center;
  color: #fff;
}

.countdown-title {
  font-size: 18px;
  margin-bottom: 15px;
}

.countdown-timer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-item span:first-child {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 5px;
}

.countdown-label {
  font-size: 14px;
  color: #666;
}

.calendar_item_word.calendar_item_word--future {
  width: 100%;
  opacity: 0.5;
}

.calendar_item_word--future .calendar_item_word_w {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  width: 100%;
}

.calendar_item .calendar_item_title .svg-icon {
  fill: #cdcdcd;
}

@media (max-width: 991.98px) {
  .first_info_item_word {
    text-align: center;
  }
  .first_info_item.first_info_right.first_info_flex {
    justify-content: center;
    padding: 0;
  }
  body .first_info_right,
  body .first_info_item {
    padding: 0;
  }
  body .first_info_right {
    text-align: center;
    margin-top: 0;
  }
  body .first_info_item_img {
    text-align: center;
  }
  body .first_info_item.first_info_right.first_info_flex {
    width: 100%;
  }
  .first_info_item_img img {
    max-height: 290px;
    object-fit: contain;
  }
  .first_info_item_word {
    flex-wrap: wrap;
  }
  .countdown-wrapper {
    width: 100%;
  }
}