.calendar {
    &_wrap {
        
    }
   
    &_rows {

        display: flex;
        flex-wrap: wrap;
        &--titles {
            @include ld {
                display: none;
            }
        }
    }
    &_item {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        width: calc(100% / 7);
        border: 1px solid #F9F9F9;
        order: 0;
        transition: all 0.4s ease;
        
        @include ld {
            width: calc(100% / 6);
        }
        @include md {
            width: calc(100% / 4);
        }
        @include sd {
            width: calc(100% / 3);
        }
        @include esd {
            width: calc(100% / 2);
        }

        &--title {
            background: #F9F9F9;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
        }
        &--date {
            min-height: 140px;
            &:hover {
                border-color: $primaryColor;
                border-radius: 5px;
            }
        }
        &--month {
            .calendar_item_title_date {
                max-width: 80%;
                text-align: right;
            }
        }
        &--empty {
            @include ld {
                display: none;
            }
        }

        &_title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &_date{
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                padding: 5px 10px;
                border-radius: 16px;
            }
            .svg-icon {
                fill: #059669;
                max-width: 14px;
                max-height: 14px;
            }
            margin-bottom: 5px;
        }
        &_word {
            &_txt {
                color: #424242;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: -0.1px;
                text-align: right;
                margin-bottom: 5px;
                opacity: 0.7;
                a {
                    color: #424242;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: -0.1px;
                    text-align: right;
                    margin-bottom: 5px;
                    opacity: 0.7;
                }
            }
            &_w {
                border-radius: 5px;
                background: #CDCDCD;
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 10px;
                text-align: center;
                padding: 15px 5px;
                @include esd {
                    font-size: 16px;
                    letter-spacing: 8px;
                }

                &.active {
                    background: $primaryColor;
                }
            }
        }

        &.active {
            background: #F1F0FD;
            .calendar_item_word_w {
                background: $primaryColor;
            }
            .calendar_item_title .svg-icon {
                fill: #059669;
            }
            .calendar_item_title_date {
                background-color: #059669;
                color: #fff;
            }
        }
        &.future {
            .calendar_item_title .svg-icon {
                fill: #4F4F4F;
            }
            .calendar_item_word {
                opacity: 0;
            }
        }
    }
}