.title {
    padding-bottom: 0;

    &__h1 {
        h1 {

            @include sd {
                text-align: left;
            }
        }
        p {

        }
    }
}

