.content {

    &--bg {
        border-bottom: 5px solid rgb(255, 255, 255);
        background: rgb(247, 247, 248);
    }
    &_wr {
        transition: all .4s ease;
        h1 {
            color: #000;
            font-family: $font2;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            letter-spacing: 0.15px;
            margin-bottom: 32px;

            @include sd {
                font-size: 28px;
            }
        }
        p {
            color: #747474;
            font-family: $font;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%;
            margin-bottom: 16px;
            @include sd {
                font-size: 14px;
            }
        }
        h2, h3, h4, h5, h6 {
            color: #000;
            font-family: $font2;
            font-weight: 400;
            line-height: 130%;
            margin: 24px 0 16px;
        }

        h2 { font-size: 35px;
            @include sd {
                font-size: 26px;
            } }
        h3 { font-size: 30px; 
            @include sd {
                font-size: 24px;
            }}
        h4 { font-size: 25px; 
            @include sd {
                font-size: 22px;
            }}
        h5 { font-size: 20px; 
            @include sd {
                font-size: 18px;
            }}
        h6 { font-size: 15px; }

        ul, ol {
            margin: 10px 0 16px 20px;
            color: #747474;
            font-family: $font;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%;
            @include sd {
                font-size: 14px;
            }
        }
        ul li {
            list-style: disc;
        }
        ol li {
            list-style: decimal;
        }

        li {
            margin-bottom: 5px;
            line-height: 145%;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            font-family: $font;
            color: #747474;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%;
            @include sd {
                font-size: 14px;
            }
        }

        th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
        }

        th {
            background-color: #f2f2f2;
            color: #333;
        }

        a {
            color: #007bff;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }

        button {
            padding: 10px 20px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }

        button:hover {
            background-color: #0056b3;
        }

        blockquote {
            border-left: 4px solid #ddd;
            padding-left: 20px;
            margin: 10px 0;
            font-style: italic;
            color: #666;
        }

        &_btn {
            overflow: hidden;
            color: $primaryColor;
            text-overflow: ellipsis;
            font-family: $font;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%;
            margin-top: 24px;
            cursor: pointer;
            @include sd {
                font-size: 14px;
            }
        }
    }
    &_inside {
        margin-top: 110px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            height: 130px;
            top: -110px;
            right: 0;
            left: 0;
            margin: auto;
            background: rgb(0,0,0);
            // background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7035189075630253) 20%, rgba(0,0,0,1) 50%);
        }
    }
    &--h1 {
        border-bottom: 5px solid rgb(255, 255, 255);
        background: rgb(247, 247, 248);
        
        .content_wr {
            h1 {
                font-size: 52px;
                margin-bottom: 24px;
                @include md {
                    font-size: 40px;
                }
                @include sd {
                    font-size: 36px;
                }
            }
            p {
                font-weight: 400;
            }
        }
    }
}

.content_wr_limited {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    max-height: 150px;

}
