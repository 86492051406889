.catalog {
    
        min-height: 60vh;
    .first_info {
        background-color: #fff;
        padding: 30px 50px;
        align-items: center;

        &_item {
            width: 80%;
            max-width: 80%;
            @include sd {
                width: 100%;
                max-width: 100%;
            }
            &.first_info_right {
                width: 20%;
                @include sd {
                    max-width: 100%;
                    width: 100%;
                    order: 1;
                }
            }
            &.first_info_left {
                @include sd {
                    order: 2;
                }
            }
            h2 {
                color: #000;
                letter-spacing: .15px;
                font-size: 26px;
            }
            &_word_other {
                margin-bottom: 0;
                margin-top: 25px;
                gap: 15px 40px;
            }
            &_word_min {
                background: #fff;
                color: #000;
                border: 1px solid rgba(0, 0, 0, .1);
            }
            .dictionary-item {
                color: #487bce;
                border-bottom: 1px dashed;
                padding-bottom: 0px;

                &:hover {
                    border-color: transparent;
                    color: #000;
                }
                &--tag {
                    padding: 10px;
                    border-radius: 6px;
                    border: 1px solid #487bce;

                }
                &--letter {
                    height: 40px;
                    width: 40px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &_img {
                img {
                    @include sd {
                        max-width: 90px;
                    }
                }
            }
        }
        &_all {
            width: 100%;
            max-width: 100%;


        }
    }
    .first_info_item_img.--min {
        justify-content: flex-end;

        @include sd {
            justify-content: flex-start;
        }
    }
    .title_h2 {
        @include sd {
            text-align: left;
        }
        h2 {
            @include sd {
                text-align: left;
            }
        }
    }

    &--word {
        .first_info_item h3,
        .first_info_item h2 {
            text-transform: none;
            margin-bottom: 30px;
            font-weight: normal;
            @include sd {
                text-align: left;
            }
            h2, h3 {
                
                @include sd {
                    text-align: left;
                }
            }
        }
    }
}

.dictionary {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 0;
    margin-top: 25px;

    &--word {
        .dictionary-item-wr {
            text-align: left;
            width: 30%;
            @include sd {
                width: calc(50% - 5px);
            }
        }
    }
}
.pagination {
    display: flex;
    gap: 10px;
    &--martop {
        margin-top: 24px;
    }
}