
@mixin xl {
    @media (max-width: 1399.98px) {
        @content;
    }
}
@mixin ld {
    @media (max-width: 1199.98px) {
        @content;
    }
}
@mixin md {
    @media (max-width: 991.98px) {
        @content;
    }
}
@mixin sd {
    @media (max-width: 767.98px) {
        @content;
    }
}
@mixin esd {
    @media (max-width: 575.98px) {
        @content;
    }
}


@mixin mineld {
    @media (min-width: 1200px) {
        @content;
    }
}
@mixin minld {
    @media (min-width: 992px) {
        @content;
    }
}
@mixin minmd {
    @media (min-width: 768px) { 
        @content;
    }
}
@mixin minsd {
    @media (min-width: 576px) {
        @content;
    }
}